import React from "react"

const MailchimpForm = () => (
  <div id='mc_embed_signup'>
    <form
      action="https://creativethinkinginstitute.us10.list-manage.com/subscribe/post?u=2bce18af8b4b1b112c094742a&amp;id=cfe55f65d7"
      method="post" id='mc-embedded-subscribe-form' name="mc-embedded-subscribe-form" target="_blank" noValidate
    >
      <div id='mc_embed_signup_scroll'>
        <div className='mc-field-group form-item'>
          <label htmlFor="mce-FNAME">First Name</label>
          <input className='register-input' type="text" name="FNAME" id="mce-FNAME"/>
        </div>
        <div className='mc-field-group form-item'>
          <label htmlFor="mce-EMAIL">Email Address</label>
          <input type="email" name="EMAIL" className='required email register-input' id="mce-EMAIL"/>
        </div>
        <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
          <input type="text" name="b_2bce18af8b4b1b112c094742a_cfe55f65d7" tabIndex="-1" />
        </div>
        <div className='clear'>
          <input type="submit" value="I want early access" name="subscribe" id="mc-embedded-subscribe"
                 className='button btn btn-navy-solid register-btn'/>
        </div>
      </div>
    </form>
  </div>
)

export default MailchimpForm
