import React from "react"
import Layout from "../components/layout"
import Register from "../components/registerBox"
import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const {
    file: {
      childMarkdownRemark: { html, frontmatter },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "pages/register.md" }) {
        childMarkdownRemark {
          html
          frontmatter {
            title
            meta {
              title
              description
            }
          }
        }
      }
    }
  `)

  return (
    <Layout meta={frontmatter.meta}>
      <Register title={frontmatter.title} html={html} />
    </Layout>
  )
}
