import React from "react"
import MailchimpForm from "../components/mailchimpForm"

const Register = ({ title, html }) => (
  <section className="sub-hero-section" id="register-form">
    <div className="container">
      <div className="row">
        <div className="gradient-bg with-radius">
          <div className="register-box-title flex flex-ac">
            <h1>{title}</h1>
          </div>
          <div className="grid-2-col">
            <div className="register-box-text" dangerouslySetInnerHTML={{ __html: html }} />
            <div className="register-box-form">
              <MailchimpForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Register
